import styled from 'styled-components';
import { Stack, Button, Row, Col, Card, CardGroup } from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';
import PerplexityChat from './PerplexityChat';
import TisiCapabilities from '../assets/tisi-capabilities.png';
import HugeResults from '../assets/huge-search-results.png';
import AiCircle from '../assets/AI-crop-circle.png';
import TisiFlipPhone from '../assets/flipphone-tisi.png';

const StyledBanner = styled.div`
  background-color: #e6e6e6;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const StyledHeading = styled.h1`
  color: #333;
  margin-bottom: 20px;
  font-size: 2rem;
  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const StyledSubHeading = styled.h2`
  color: #555;
  margin-bottom: 15px;
  font-size: 1.5rem;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  background-color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #efefef;
  height: 100%;
`;

const StyledCardTitle = styled(Card.Title)`
  color: #ff1312;
  font-size: 1.25rem;
  font-family: 'Roboto Mono', monospace;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const StyledCardText = styled(Card.Text)`
  font-size: 0.9rem;
  font-family: 'Roboto', sans-serif;
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const StyledButton = styled(Button)`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  margin: 20px 0;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  background-color: #ff1312;
  color: white;
  border: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #e60000;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 19, 18, 0.3);
  }
`;


function Home() {
  return (
    <div>
      <StyledBanner>
        <Row>
          <Col>
            <StyledHeading>Welcome to Text It, Search It!</StyledHeading>
            <StyledSubHeading>Tisi is a text-to-search AI service</StyledSubHeading>
            <StyledSubHeading>Receive instant answers to your queries, just a text msg away</StyledSubHeading>
          </Col>
        </Row>
        <Row xs={1} md={2} lg={4} className="g-4">
          {[
            { img: HugeResults, title: "Tisi's Perfect For", text: "Digital detoxers seeking a break from internet and smart tech. Tech minimalists who prefer simple searching. Old flip phone users without a browser and want to be able to query for web information." },
            { img: AiCircle, title: "How It Works", text: "Users tired of receiving endless search results and information from search engines can simply SMS msg their question to our 10DLC number and receive a concise and accurate SMS answer to their phone in seconds." },
            { img: TisiCapabilities, title: "What Can You Search For?", text: "It is just an sports scores, weather updates, breaking news to general knowledge - TISI has you covered." },
            { img: TisiFlipPhone, title: "Get Started Now", text: "Experience the power of AI search without the digital clutter. Try TISI today!" }
          ].map((card, idx) => (
            <Col key={idx}>
              <StyledCard>
                <Card.Img variant="top" src={card.img} style={{ maxWidth: '90%', height: 'auto', margin: '10px auto' }} />
                <Card.Body>
                  <StyledCardTitle>{card.title}</StyledCardTitle>
                  <StyledCardText>{card.text}</StyledCardText>
                </Card.Body>
              </StyledCard>
            </Col>
          ))}
        </Row>
        <Stack direction="horizontal" className="justify-content-center">
          <StyledButton as={RouterLink} to="/userauth">Login or Register</StyledButton>
        </Stack>
      </StyledBanner>
      <PerplexityChat />
    </div>
  );
}

export default Home;
