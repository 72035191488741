import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import TisiLogo from '../assets/tisi-logo-light.png';
import PlaceholderImage from '../assets/AI-crop-circle.png'; // Replace with your actual image

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 20px;
`;

const StyledCard = styled(Card)`
  background-color: #efefef;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

const Logo = styled.img`
  max-width: 80%;
  height: auto;
  margin: 0 auto 0.5rem;
  display: block;

  @media (min-width: 768px) {
    max-width: 60%;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: 576px) {
    font-size: 2.5rem;
  }

  @media (min-width: 992px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
  text-align: center;

  @media (min-width: 576px) {
    font-size: 1.2rem;
  }

  @media (min-width: 992px) {
    font-size: 1.4rem;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
  flex: 1 1 auto;
  max-width: 200px;
  background-color: #333;
  color: #efefef;
  font-family: 'Roboto', sans-serif;

  &:hover {
    transform: translateY(-2px);
    background-color: #333;
    color: #efefef;
  }

  @media (min-width: 576px) {
    flex: 0 1 auto;
  }
`;

function ComingSoon() {
  return (
    <PageWrapper>
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <StyledCard>
              <Card.Body>
                <Logo src={TisiLogo} alt="Tisi Logo" />
                <Title>Releasing Soon</Title>
                <Subtitle>
                  Tisi - Your text-to-search online answer engine service is almost here! Utilize AI and SMS messaging to get answers to your questions.
                </Subtitle>
                <ImageWrapper>
                  <StyledImage src={PlaceholderImage} alt="Text-to-search using AI" />
                </ImageWrapper>
                <ButtonWrapper>
                  <StyledButton variant="primary" href="mailto:john@textitsearchit.com">
                    <FontAwesomeIcon icon={faEnvelope} /> Contact Us
                  </StyledButton>
                  <StyledButton variant="secondary" href="https://www.sleke.io">
                    A Product of SLEKE.
                  </StyledButton>
                </ButtonWrapper>
              </Card.Body>
            </StyledCard>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
}

export default ComingSoon;

